@import 'colors';

.banner {
	background-image: url("https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584521871/myhotchop/mealban2_yh4kcq.jpg");
}
.meal-heading {
	position: relative;
	text-align: center;
	top: 40px;
}
.ads-banner-1 {
	background-image: url("https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584393475/myhotchop/eba_mngnwc.jpg");
}
.ads-banner-2 {
	background-image: url("https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584397000/myhotchop/banner-2_hslque.jpg");
}
.add-cart {
	position: relative;
	top: 12px;
	right: 5px;
	background-color: $main-color;
	width: 150px;
	color: #fff;
	padding: 5px;
	border-radius: 3%;
	float: right;
	margin-right: -6px;
}

.add-cart-modal {
	position: relative;
	top: -42px;
	right: 20px;
	background-color: $main-color;
	width: 90px;
	color: #fff;
	padding: 7px;
	border-radius: 3%;
	float: right;
	margin-right: -6px;
}

.confirm-modal {
	position: relative;
	top: 0px;
	right: 20px;
	background-color: $app-success-color;
	width: 90px;
	color: #fff;
	padding: 7px;
	border-radius: 3%;
	float: left;
	margin-left: 26px;

	&:hover {
		background-color: $app-success-color-hover;
		color: #fff;
	}
}

.confirm-no-modal {
	position: relative;
	top: 0px;
	right: 20px;
	background-color: $app-fail-color;
	width: 90px;
	color: #fff;
	padding: 7px;
	border-radius: 3%;
	float: right;
	margin-left: 26px;

	&:hover {
		background-color: $app-fail-color-hover;
		color: #fff;
	}
}

.message-header {
	color: #fff;
	background-color: $app-red-color;
	padding: 12px;
	height: 45px;
	font-size: 13px;
	width: 100%;
}

.error {
	color: $app-red-color;
	position: relative;
	top: -23px;
	font-size: 13px;
	float: left;
}
.error-msg {
	color: $app-red-color;
	font-size: 13px;
	text-align: center;
	margin-bottom: 35px;
}
.success-msg {
	color: $app-success-color;
	font-size: 13px;
	margin-bottom: 35px;
}
.cart-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	padding-top: 15px;
	padding-bottom: 30px;
	background-color: #07BC0C;
	color: white;
	text-align: center;
	z-index: 1;
  }

  .modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }


.modal-main {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 320px;

	height: auto;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
	position: relative;
	top: -155px;
	right: -90px;
  }
  
  .close:hover,
  .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
  }

  .select-group {
    color: #847577;
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
	height:40px;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    background-color: rgb(255, 255, 255);
}

.btn-link-disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}

.order-header {
	color: #000;
	font-size: 14px;
}

.accordion-bg {
	background-color: $main-color !important;
	color: #fff !important;
}
.heading h4 {
	white-space: pre-wrap !important;
}
.message {
	color: $main-color;
	font-size: 19px;
	text-align: center;
}